<template>
  <a-spin :spinning="spinShow">
    <a-card style="margin-bottom: 30px">
      <a-steps class="steps" :current="currentTab">
        <a-step title="登记 "/>
        <a-step title="回访 "/>
        <!--        <a-step title="确认"/>-->
        <a-step title="完成 "/>
      </a-steps>
    </a-card>
    <a-timeline style="margin-top: 16px;margin-left: 21px">
      <a-timeline-item>
        <a-avatar slot="dot" style="backgroundColor:#0099FF" :size="53">
          登记信息
        </a-avatar>
        <a-descriptions :column="2" style="margin-top: 16px;margin-left: 21px">
          <a-descriptions-item label="人员名称">{{ this.formItem.reportRecord.fromName }}</a-descriptions-item>
          <a-descriptions-item label="人员电话">{{ this.formItem.reportRecord.fromPhone }}</a-descriptions-item>
          <a-descriptions-item label="人员地址">
            <a-tooltip>
              <template slot="title">
                {{ this.formItem.reportRecord.fromAddress }}
              </template>
              {{ this.formItem.reportRecord.fromAddress.length > 15 ? this.formItem.reportRecord.fromAddress.substring(0, 15) + '...' : this.formItem.reportRecord.fromAddress }}
            </a-tooltip>
          </a-descriptions-item>
          <a-descriptions-item label="上报类型">
            {{ formItem.reportRecord.type === 'TEL' ? '电话' : formItem.reportRecord.type === 'WECHAT' ? '微信' : '其他' }}
          </a-descriptions-item>
          <a-descriptions-item label="上报时间">{{ this.formItem.reportRecord.fromTime }}</a-descriptions-item>
          <a-descriptions-item label="上报原因">
            <a-tooltip>
              <template slot="title">
                {{ this.formItem.reportRecord.reason }}
              </template>
              {{ this.formItem.reportRecord.reason.length > 15 ? this.formItem.reportRecord.reason.substring(0, 15) + '...' : this.formItem.reportRecord.reason }}
            </a-tooltip>
          </a-descriptions-item>
          <a-descriptions-item label="集污池类型">
            <a-tag
              :color="formItem.reportRecord.gatherSpotType===spotTypeEnum.DANWENG.value? 'blue' :formItem.reportRecord.gatherSpotType===spotTypeEnum.JIWUCHI.value?'red' : 'green'">
              {{ getSpotType(formItem.reportRecord) }}
            </a-tag>
          </a-descriptions-item>
          <a-descriptions-item label="集污点编号" v-if="this.formItem.reportRecord.spot">
            {{ this.formItem.reportRecord.spot.no }}
          </a-descriptions-item>
          <a-descriptions-item label="集污点名称" v-if="this.formItem.reportRecord.spot">
            {{ this.formItem.reportRecord.spot.name }}
          </a-descriptions-item>
          <a-descriptions-item label="区域名称" v-if="this.formItem.reportRecord.spot">{{
              this.formItem.reportRecord.spot.areaName
            }}
          </a-descriptions-item>
          <a-descriptions-item label="审核状态" v-if="this.formItem.reportRecord.spot">{{
              this.formItem.reportRecord.spot.checkStatus === 'UN_CHECK' ? '未审核' :
                this.formItem.reportRecord.spot.checkStatus === 'PASS' ? '审核通过' : '审核驳回'
            }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="2" style="margin-left: 21px">
          <a-descriptions-item label="图片信息" v-if="images.length">
            <template>
              <qiniu-image-upload ref="qiniuImageRef" type="view" :items="images" style="margin-top: -15px"/>
            </template>
          </a-descriptions-item>
        </a-descriptions>
      </a-timeline-item>
      <a-timeline-item
        v-if="this.formItem.reportRecord.handleStatus=== 'FEEDBACK_COMPLETE'||this.formItem.reportRecord.handleStatus=== 'CONFIRM_COMPLETE'">
        <a-avatar slot="dot" style="backgroundColor:#0099FF" :size="53">
          回访信息
        </a-avatar>
        <a-descriptions :column="2" style="margin-top: 16px;margin-left: 21px">
          <a-descriptions-item label="回访人">{{ this.formItem.reportRecord.feedbackPerson }}</a-descriptions-item>
          <a-descriptions-item label="回访时间">{{ this.formItem.reportRecord.feedbackTime }}</a-descriptions-item>
          <a-descriptions-item label="回访结果">{{ this.formItem.reportRecord.feedbackResult }}</a-descriptions-item>
        </a-descriptions>
      </a-timeline-item>
      <a-timeline-item v-if="this.formItem.reportRecord.handleStatus=== 'CONFIRM_COMPLETE'">
        <a-avatar slot="dot" style="backgroundColor:#0099FF" :size="53">
          确认信息
        </a-avatar>
        <a-descriptions :column="2" style="margin-top: 16px;margin-left: 21px">
          <a-descriptions-item label="确认人">{{ this.formItem.reportRecord.confirmPerson }}</a-descriptions-item>
          <a-descriptions-item label="确认时间">{{ this.formItem.reportRecord.confirmTime }}</a-descriptions-item>
          <a-descriptions-item label="确认结果">{{ this.formItem.reportRecord.confirmResult }}</a-descriptions-item>
        </a-descriptions>
      </a-timeline-item>
    </a-timeline>
  </a-spin>
</template>

<script>
import SERVICE_URLS from '@/api/service.url'
import { damageReportFormVO } from './common/common'
import QiniuImageUpload from '../../../../components/QiniuUpload/QiniuImageUpload'
import { spotTypeEnum } from '@/constant/spot/spotType'

export default {
  name: 'Detail',
  data () {
    return {
      formItem: damageReportFormVO(),
      carRecordId: null, // 车俩记录的ID
      spinShow: false,
      currentTab: 0,
      images: [],
      tempBind: '未绑定工单',
      ticketInfoId: '', //ticketInfo表的主键id
      ticketDetailModal: false,
      carDetailModal: false,
      spotTypeEnum: spotTypeEnum
    }
  },
  // eslint-disable-next-line standard/object-curly-even-spacing
  components: { QiniuImageUpload },
  methods: {
    loadData (id) {
      this.formItem = damageReportFormVO()
      this.carRecordId = null
      this.$http(this, {
        url: SERVICE_URLS.csgz.reportRecord.view,
        params: {
          id: id
        },
        noTips: 'true',
        success: (data) => {
          Object.assign(this.formItem, data.body)
          this.images = data.body.images
          this.handleDate(data.body)
        }
      })
    },
    handleDate () {
      if (this.formItem.reportRecord.handleStatus === 'SCHEDULED') {
        this.currentTab = 0
      } else if (this.formItem.reportRecord.handleStatus === 'PROCESSED') {
        this.currentTab = 1
      } else if (this.formItem.reportRecord.handleStatus === 'FEEDBACK_COMPLETE') {
        this.currentTab = 2
      }
    },
    getSpotType (item) {
      const type = item.gatherSpotType
      if (type === spotTypeEnum.JIWUCHI.value) {
        return '集污池'
      } else if (type === spotTypeEnum.SANGE.value) {
        return '三格化粪池'
      } else if (type === spotTypeEnum.DANWENG.value) {
        return '单翁'
      } else if (type === spotTypeEnum.LANI.value) {
        return '拉泥'
      } else if (type === spotTypeEnum.GONGCE.value) {
        return '公厕'
      } else {
        return '其他'
      }
    }
  }
}
</script>
